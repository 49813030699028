import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"



// Dashboard
import Dashboard from "../pages/Dashboard/index"

//EggProduction
import BatchEP from "pages/EggProduction/Batch"
import BatchDetailEP from "pages/EggProduction/Batch/BatchDetail"
import EggType from "pages/EggProduction/EggType"
import FarmEP from "pages/EggProduction/Farm"
import FarmDetailEP from "pages/EggProduction/Farm/FarmDetail"
import FoodType from "pages/EggProduction/FoodType"
import MortalityType from "pages/EggProduction/MortalityType"
import UsersEP from "pages/EggProduction/Users"


//ChickenProduction
import BatchCP from "pages/ChickenProduction/Batch"
import UsersCP from "pages/ChickenProduction/Users"
import Standard from "pages/ChickenProduction/Standards"
import FarmCP from "pages/ChickenProduction/Farm"
import FarmDetail from "pages/ChickenProduction/Farm/FarmDetail"
import BatchDetail from "pages/ChickenProduction/Batch/BatchDetail"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  //EggProduction
  { path: "/epbatch", component: BatchEP },
  { path: "/epbatchdetail", component: BatchDetailEP },
  { path: "/epeggtype", component: EggType },
  { path: "/epfarm", component: FarmEP },
  { path: "/epfarmdetail", component: FarmDetailEP },
  { path: "/epfoodtype", component: FoodType },
  { path: "/epmortalitytype", component: MortalityType },
  { path: "/epusers", component: UsersEP },
  //ChickenProduction
  { path: "/cpbatch", component: BatchCP },
  { path: "/cpusers", component: UsersCP },
  { path: "/cpstandard", component: Standard },
  { path: "/cpfarm", component: FarmCP },
  { path: "/cpfarmdetail", component: FarmDetail },
  { path: "/cpbatchdetail/:id", component: BatchDetail },

  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { publicRoutes, authProtectedRoutes }
