import axios from "axios";

const user = JSON.parse(localStorage.getItem("authUser"));

// const API_URL = "http://209.105.248.214:8099/"
const API_URL = "http://209.105.248.227:8099/"

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.defaults.headers.common["Authorization"] = "Bearer " + user?.token

axiosApi.interceptors.response.use(
  response => response,
  async function (error){
    const access_token = localStorage.getItem("authUser");
    if ((error.response.status === 401 || error.response.status === 403 ) && access_token) {
      localStorage.removeItem("authUser");
      window.location.href = "/login"
    }
    return Promise.reject(error);
  }
);

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}
