import React, { useMemo, useEffect, useState } from "react"
import {
  UncontrolledTooltip,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Col,
  Row,
  Label,
  Card,
  CardBody,
  CardTitle,
  Dropdown,
  Badge,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledDropdown
} from "reactstrap"
import TableContainer from '../../../../components/Common/TableContainer';
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import Select from "react-select";

import classnames from "classnames"

import InputMask from "react-input-mask";

import { Link } from "react-router-dom";

import { useFormik } from "formik"

import * as Yup from "yup";

import img5 from "../../../../assets/images/companies/img-5.png";

const BatchDetail = () => {
  //meta title
  document.title = "NattoLabs - Painel Adminitrativo";

  const [settingsMenu, setSettingsMenu] = useState(false);
  const [customActiveTab, setcustomActiveTab] = useState("1");

  //const [aviaryList, setAviaryList] = useState([{ id: 1, number: "01", capacity: 25000 }]);
  const [aviaryList, setAviaryList] = useState([{id:1,date:"09/09/2023",age:32,status:"PENDENTE",register_at:"09/09/2023 - 15:34",total:2340,turn:6}]);
  const [modalAddAviary, setModalAddAviary] = useState(false);
  const [isEditAviary, setIsEditAviary] = useState(false);
  const [aviary, setAviary] = useState(null);

  const [batchList, setBatchList] = useState([{id:1,date:"09/09/2023",age:32,status:"PENDENTE",register_at:"09/09/2023 - 15:34",total_male:"34.5 KG",total_female:"37.5 KG"}]);
  const [modalAddBatch, setModalAddBatch] = useState(false);
  const [isEditBatch, setIsEditBatch] = useState(false);
  const [batch, setBatch] = useState(null);


  const [mortalityList, setMortalityList] = useState([{id:1,date:"09/09/2023",age:32,status:"PENDENTE",register_at:"09/09/2023 - 15:34",mortality_male:"35",mortality_female:"37"}]);
  const [modalAddMortality, setModalAddMortality] = useState(false);
  const [isEditMortality, setIsEditMortality] = useState(false);
  const [mortality, setMortality] = useState(null);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (aviary && aviary.id) || '',
      number: (aviary && aviary.number) || '',
      capacity: (aviary && aviary.capacity) || '',
    },
    validationSchema: Yup.object({
      number: Yup.string().required("Por favor digite a Número"),
      capacity: Yup.string().required("Por favor digite o Capacidade"),

    }),
    onSubmit: (values) => {
    
      if (isEditAviary) {
        const updateOrder = {
          aviary_id: aviary ? aviary.id : 0,
          number: values.number,
          capacity: values.capacity,
        };



      } else {

        const newOrder = {
          id:aviaryList.length +1,
          number: values["number"],
          capacity: values["capacity"],
        };

        addAviaryToTable(newOrder);
        validation.resetForm();

        // save new order
      }

    },
  });




  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        disableFilters: true,

      },
      {
        Header: 'Data',
        accessor: 'date',
        disableFilters: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Badge
            className={"font-size-12 badge-soft-success"}
          >
            Enviado
          </Badge>);
        }
      },
      {
        Header: 'Data de Apontamento',
        accessor: 'register_at',
        disableFilters: true,
      },
      {
        Header: 'Total Ovos',
        accessor: 'total',
        disableFilters: true,
      },
      {
        Header: 'Coletas',
        accessor: 'turn',
        disableFilters: true,
      },
      {
        Header: 'Ações',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown direction="start">
              <DropdownToggle
                href="#"
                className="card-drop"
                tag="i"
              >
                <i className="mdi mdi-dots-horizontal font-size-18" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">


                <DropdownItem
                  onClick={() => {
                    const aviaryData = cellProps.row.original;
                    handleOrderClick(aviaryData);
                  }}
                >
                  <i className="mdi mdi-note-plus font-size-16 text-danger me-1" />{" "}
                  Ver Detalhes
                </DropdownItem>

                <DropdownItem
                  href={"#"}
                >
                  <i className="mdi mdi-pencil font-size-16 text-danger me-1" />{" "}
                  Corrigir Apontamento
                </DropdownItem>

                <DropdownItem
                  href={"#"}
                >
                  <i className="mdi mdi-delete-forever font-size-16 text-danger me-1" />{" "}
                  Apagar Apontamentos
                </DropdownItem>

              </DropdownMenu>

            </UncontrolledDropdown>
          );
        }
      },
    ],
    []
  );



  const columnsBatch = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        disableFilters: true,

      },
      {
        Header: 'Data',
        accessor: 'date',
        disableFilters: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Badge
            className={"font-size-12 badge-soft-info"}
          >
            PENDENTE
          </Badge>);
        }
    
      },
      {
        Header: 'Data de Apontamento',
        accessor: 'register_at',
        disableFilters: true,
      },
      {
        Header: 'Consumo Macho',
        accessor: 'total_male',
        disableFilters: true,
      },
      {
        Header: 'Consumo Fêmea',
        accessor: 'total_female',
        disableFilters: true,
      },
      {
        Header: 'Ações',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown direction="start">
              <DropdownToggle
                href="#"
                className="card-drop"
                tag="i"
              >
                <i className="mdi mdi-dots-horizontal font-size-18" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                  onClick={() => {
                    const aviaryData = cellProps.row.original;
                    handleOrderClick(aviaryData);
                  }}
                >
                  <i className="mdi mdi-eye-outline font-size-16 text-danger me-1" />{" "}
                  Ver Detalhes
                </DropdownItem>

                <DropdownItem
                  href={"#"}
                >
                  <i className="mdi mdi-pencil font-size-16 text-danger me-1" />{" "}
                  Corrigir Apontamento
                </DropdownItem>

                <DropdownItem
                  href={"#"}
                >
                  <i className="mdi mdi-delete-forever font-size-16 text-danger me-1" />{" "}
                  Apagar Apontamento
                </DropdownItem>

              </DropdownMenu>

            </UncontrolledDropdown>
          );
        }
      },
    ],
    []
  );


  const columnsMotality = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        disableFilters: true,

      },
      {
        Header: 'Data',
        accessor: 'date',
        disableFilters: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Badge
            className={"font-size-12 badge-soft-info"}
          >
            PENDENTE
          </Badge>);
        }
    
      },
      {
        Header: 'Data de Apontamento',
        accessor: 'register_at',
        disableFilters: true,
      },
      {
        Header: 'Mortalidade Macho',
        accessor: 'mortality_male',
        disableFilters: true,
      },
      {
        Header: 'Mortalidade Fêmea',
        accessor: 'mortality_female',
        disableFilters: true,
      },
      {
        Header: 'Ações',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown direction="start">
              <DropdownToggle
                href="#"
                className="card-drop"
                tag="i"
              >
                <i className="mdi mdi-dots-horizontal font-size-18" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                  onClick={() => {
                    const aviaryData = cellProps.row.original;
                    handleOrderClick(aviaryData);
                  }}
                >
                  <i className="mdi mdi-eye-outline font-size-16 text-danger me-1" />{" "}
                  Ver Detalhes
                </DropdownItem>

                <DropdownItem
                  href={"#"}
                >
                  <i className="mdi mdi-pencil font-size-16 text-danger me-1" />{" "}
                  Corrigir Apontamento
                </DropdownItem>

                <DropdownItem
                  href={"#"}
                >
                  <i className="mdi mdi-delete-forever font-size-16 text-danger me-1" />{" "}
                  Apagar Apontamento
                </DropdownItem>

              </DropdownMenu>

            </UncontrolledDropdown>
          );
        }
      },
    ],
    []
  );

  const handleOrderClick = arg => {

    setIsEditAviary(true);

    setAviary({ id: arg.id, number: arg.number, capacity: arg.capacity });

   
    toggleModalAddAviary();



  };

  const handleOrderClicks = () => {

    setIsEditAviary(false);
    toggleModalAddAviary();


  };


  function toggleModalAddAviary() {
    if (modalAddAviary) {
      setModalAddAviary(false);
      setAviary(null);
    } else {
      setModalAddAviary(true)
    }

  }



  const addAviaryToTable = (value) => {
    const modifiedRows = [...aviaryList];

    modifiedRows.push(value);
    setAviaryList(modifiedRows);

    console.log(modifiedRows);
    toggleModalAddAviary();
  }







  const handleBatchClick = arg => {

    setIsEditBatch(true);

    setBatch({ id: arg.id, number: arg.number, capacity: arg.capacity });

   
    toggleModalAddBatch();



  };

  const handleBatchClicks = () => {

    setIsEditBatch(false);
    toggleModalAddBatch();


  };


  function toggleModalAddBatch() {
    if (modalAddBatch) {
      setModalAddBatch(false);
      setBatch(null);
    } else {
      setModalAddBatch(true)
    }

  }



  const addABatchToTable = (value) => {
    const modifiedRows = [...batchList];

    modifiedRows.push(value);
    setBatchList(modifiedRows);

    console.log(modifiedRows);
    toggleModalAddBatch();
  }

  //Setting Menu
  const toggleSettings = () => {
    setSettingsMenu(!settingsMenu)
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Produção de Frango" breadcrumbItem="Detalhe do Lote" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>


                    <Col lg="4">
                      <div className="d-flex">
                        <div className="flex-grow-1 align-self-center">
                          <div className="text-muted">

                            <h5 className="mb-1">Lote 3123-35-01 </h5>
                            <p className="mb-0">Granja de Agrestina | Aviário 01 | Idade 3 dias</p>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col lg="8" className="align-self-center">
                      <div className="text-lg-center mt-4 mt-lg-0">
                        <Row>
                          <Col xs="4">
                            <div>
                              <p className="text-muted text-truncate mb-2">
                                Produzidos Hoje
                              </p>
                              <h5 className="mb-0">50 Ovos</h5>
                            </div>
                          </Col>
 
                          <Col xs="4">
                            <div>
                              <p className="text-muted text-truncate mb-2">
                                Produzidos Total
                              </p>
                              <h5 className="mb-0">35.00 Ovos</h5>
                            </div>
                          </Col>

                          <Col xs="4">
                            <div>
                              <p className="text-muted text-truncate mb-2">
                                Mortalidade
                              </p>
                              <h5 className="mb-0">50 / <Badge className="me-2 bg-danger">-1,34%</Badge></h5>
                            </div>
                          </Col>

                        </Row>
                      </div>
                    </Col>

                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom nav-justified justify-content-center">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">Coleta de Ovos</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustom("2");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">Alimentação</span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "3",
                        })}
                        onClick={() => {
                          toggleCustom("3");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">Mortalidade</span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent
                    activeTab={customActiveTab}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">

                          <TableContainer
                            columns={columns}
                            data={aviaryList}
                            isGlobalFilter={true}
                            customPageSize={10}
                            isAddOptions={true}
                            customPageSizeOptions={2}
                            handleOrderClicks={handleOrderClicks}
                          //className="custom-header-css"
                          />

                          <Modal
                            size="lg"
                            isOpen={modalAddAviary}
                            toggle={() => {
                              toggleModalAddAviary();
                            }}
                          >
                            <ModalHeader toggle={toggleModalAddAviary} tag="h4">
                              {!!isEditAviary ? "Editar Aviário" : "Adicionar Aviário"}
                            </ModalHeader>

                            <ModalBody>
                              <Form
                                onSubmit={validation.handleSubmit}
                              >
                                <Row>
                                  <Col className="col-12">
                                    <div className="mb-3">
                                      <Label className="form-label">Número</Label>
                                      <Input
                                        name="number"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.number || ""}
                                        invalid={
                                          validation.touched.number && validation.errors.number ? true : false
                                        }
                                      />
                                      {validation.touched.number && validation.errors.number ? (
                                        <FormFeedback type="invalid">{validation.errors.number}</FormFeedback>
                                      ) : null}
                                    </div>


                                    <div className="mb-3">
                                      <Label className="form-label">Capacidade</Label>
                                      <Input
                                        name="capacity"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.capacity || ""}
                                        invalid={
                                          validation.touched.capacity && validation.errors.capacity ? true : false
                                        }
                                      />
                                      {validation.touched.capacity && validation.errors.capacity ? (
                                        <FormFeedback type="invalid">{validation.errors.capacity}</FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>

                                </Row>

                                <Row>
                                  <Col>
                                    <div className="text-end">
                                      <button
                                        type="submit"
                                        className="btn btn-success save-user"
                                      >
                                        Salvar
                                      </button>
                                    </div>
                                  </Col>
                                </Row>

                              </Form>
                            </ModalBody>
                          </Modal>
                        </Col>
                      </Row>
                    </TabPane>
                 
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <TableContainer
                            columns={columnsBatch}
                            data={batchList}
                            isGlobalFilter={true}
                            customPageSize={10}
                            isAddOptions={true}
                            customPageSizeOptions={2}
                      
                          //className="custom-header-css"
                          />



                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12">
                          <TableContainer
                            columns={columnsMotality}
                            data={mortalityList}
                            isGlobalFilter={true}
                            customPageSize={10}
                            isAddOptions={true}
                            customPageSizeOptions={2}
                      
                          //className="custom-header-css"
                          />



                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>

          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
}

export default BatchDetail;
