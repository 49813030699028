import React, { useMemo, useEffect, useState } from "react"
import {
  UncontrolledTooltip,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Col,
  Row,
  Label,
  Card,
  CardBody,
  CardTitle,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import TableContainer from '../../../components/Common/TableContainer';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Select from "react-select";

import InputMask from "react-input-mask";

import { Link } from "react-router-dom";

import { useFormik } from "formik"
import * as Yup from "yup";

const Users = () => {
  //meta title
  document.title = "NattoLabs - Painel Adminitrativo";

  const [selectedMulti, setselectedMulti] = useState(null);
  const [modalAddUser, setModalAddUser] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [user, setUser] = useState(null);
  const [userList, setUserList] = useState([{ id: 1, name: "Randane Silva", email: "randane@gmail.com",username:"randane.silva",farm_id:1,farm_name:"Granja Agrestina",status:"ATIVO" }]);


  
const optionGroup = [
  { label: "P18", value: "P18" },
  { label: "P12", value: "P12" },
  { label: "P01", value: "P01" }
];


  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (user && user.id) || '',
      name: (user && user.name) || '',
      password: (user && user.password) || '',
      email: (user && user.email) || '',
      username: (user && user.username) || '',
      farm_id: (user && user.farm_id) || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Por favor digite o Nome"),
      password: Yup.string().required("Por favor digite a Senha"),
      email: Yup.string().required("Por favor digite o Email"),
      username: Yup.string().required("Por favor digite o Nome de Usuário"),
    }),
    onSubmit: (values) => {
    
      if (isEdit) {
        const updateOrder = {
          user_id: user ? user.id : 0,
          name: values.name,
          password: values.password,
          email: values.email,
          username: values.username,
          farm_id:values.farm_id
        };



      } else {

        const newOrder = {
          id:userList.length +1,
          name: values["name"],
          password: values["password"],
          email: values["email"],
          username: values["username"],
          farm_id: values["farm_id"],
        };

        addUserToTable(newOrder);
        validation.resetForm();

        // save new order
      }

    },
  });


  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti);
  }

  const handleOrderClick = arg => {
   
    setIsEdit(true);

    setUser({ id: arg.id, name: arg.name, email:arg.email,username:arg.username,farm_id:arg.farm_id,farm_name:arg.farm_name,status:arg.status});

    toggleModalAddUser();
    


  };

  const handleOrderClicks = () => {
    
    setIsEdit(false);
    toggleModalAddUser();


  };


  function toggleModalAddUser() {
    if(modalAddUser)
    {
      setModalAddUser(false);
      setUser(null);
    }else{
      setModalAddUser(true)
    }

  }



  const addUserToTable = (value) => {
    const modifiedRows = [...userList];

    modifiedRows.push(value);
    setUserList(modifiedRows);
   
    toggleModalAddUser();
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        disableFilters: true,

      },
      {
        Header: 'Nome',
        accessor: 'name',
        disableFilters: true,
      },
      {
        Header: 'Email',
        accessor: 'email',
        disableFilters: true,
      },
      {
        Header: 'Nome de Usuário',
        accessor: 'username',
        disableFilters: true,
      },
      {
        Header: 'Granja',
        accessor: 'farm_name',
        disableFilters: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Badge
            className={"font-size-12 bg-success"}
          >
           ATIVO
          </Badge>);
        }
      },
      {
        Header: 'Ações',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown direction="start">
              <DropdownToggle
                href="#"
                className="card-drop"
                tag="i"
              >
                <i className="mdi mdi-dots-horizontal font-size-18" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">




                <DropdownItem
                  href={"/cpfarmdetail/"}
                >
                  <i className="mdi mdi-lock-reset font-size-16 text-danger me-1" />{" "}
                  Resetar Senha
                </DropdownItem>

                <DropdownItem
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleOrderClick(userData);
                }}
                >
                  <i className="mdi mdi-pencil font-size-16 text-danger me-1" />{" "}
                  Editar
                </DropdownItem>


                <DropdownItem
                  href={"#"}
                >
                  <i className="mdi mdi-cancel font-size-16 text-danger me-1" />{" "}
                  Desabilitar
                </DropdownItem>






              </DropdownMenu>

            </UncontrolledDropdown>
          );
        }
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Produção de Frango" breadcrumbItem="Listagem de Granja" />
          <TableContainer
            columns={columns}
            data={userList}
            isGlobalFilter={true}
            customPageSize={10}
            isAddOptions={true}
            customPageSizeOptions={2}
            handleOrderClicks={handleOrderClicks}
          //className="custom-header-css"
          />

          <Modal
            size="lg"
            isOpen={modalAddUser}
            toggle={() => {
              toggleModalAddUser();
            }}
          >
            <ModalHeader toggle={toggleModalAddUser} tag="h4">
              {!!isEdit ? "Editar Usuário" : "Adicionar Usuário"}
            </ModalHeader>

            <ModalBody>
              <Form
                onSubmit={validation.handleSubmit}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Nome</Label>
                      <Input
                        name="name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                          validation.touched.name && validation.errors.name ? true : false
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                      ) : null}
                    </div>


                    <div className="mb-3">
                      <Label className="form-label">Nome de Usuário</Label>
                      <Input
                        name="username"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.username || ""}
                        invalid={
                          validation.touched.username && validation.errors.username ? true : false
                        }
                      />
                      {validation.touched.username && validation.errors.username ? (
                        <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                      ) : null}
                    </div>



                        { !isEdit &&
                          <div  className="mb-3">
                          <Label className="form-label">Senha</Label>
                          <Input
                            name="password"
                            type="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password && validation.errors.password ? true : false
                            }
                          />
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          ) : null}
                        </div>
                        }



                    <div className="mb-3">
                      <Label className="form-label">Email</Label>
                      <Input
                        name="email"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email && validation.errors.email ? true : false
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                      ) : null}
                    </div>


                    <div className="mb-3">
                          <Label className="form-label">Granja</Label>
                            <Input
                            name="farm_id"
                            type="select"
                            className="form-select"
                            onChange={e => {
                              getSystemByArea(e.target.value)
                            }}
                            >
                                <option value="">Selecione...</option>
                                {/* {(farm || []).map((row, key) => (
                                <option key={key} value={row.id}>{row.name}</option>
                                ))} */}
                            </Input>

                        </div>

                        <div className="mb-3">
                          <label className="control-label">
                            Núcleo
                          </label>
                          <Select
                            value={selectedMulti}
                            isMulti={true}
                            placeholder={"Selecione..."}
                            onChange={() => {
                              handleMulti();
                            }}
                            options={optionGroup}
                          
                            classNamePrefix="select2-selection"
                          />
                        </div>
                  </Col>

                </Row>

                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Salvar
                      </button>
                    </div>
                  </Col>
                </Row>

              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Users;
