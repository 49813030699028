import React, { useMemo, useEffect, useState } from "react"
import {
  UncontrolledTooltip,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Input,
  FormFeedback,
  Col,
  Row,
  Label,
  Card,
  CardBody,
  CardTitle,
  Dropdown,
  Badge,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledDropdown
} from "reactstrap"
import TableContainer from '../../../../components/Common/TableContainer';
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import Select from "react-select";


import { get,post } from "../../../../helpers/api_helper";
import * as url from "../../../../helpers/url_helper";

import classnames from "classnames"


import { useFormik } from "formik"

import * as Yup from "yup";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const BatchDetail = (props) => {
  //meta title
  document.title = "NattoLabs - Painel Adminitrativo";

  const MySwal = withReactContent(Swal);

  const [settingsMenu, setSettingsMenu] = useState(false);
  const [customActiveTab, setcustomActiveTab] = useState("1");

  const [batchInfo,setBatchInfo] = useState({});
  const [mortalityList, setMortalityList] = useState([]);
  const [modalAddAviary, setModalAddAviary] = useState(false);
  const [isEditAviary, setIsEditAviary] = useState(false);
  const [aviary, setAviary] = useState(null);

  const [weightList, setWeightList] = useState([]);
  const [modalAddBatch, setModalAddBatch] = useState(false);

  const [weightValues, setWeightValues] = useState([])

  useEffect(()=>{
    const batchId = props.match.params.id;
    getBatchInfo(batchId).finally(()=>{
      // setLoader(false)
    });
  },[]);


  async function getBatchInfo(batchId){
    // setLoader(true)
   await get(url.GET_ENTITY_BY_ID+batchId).then((result)=>{
    setBatchInfo(result);
    setMortalityList(result.mortalityValues);
    setWeightList(result.weightValues);
   });


    
  }


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (aviary && aviary.id) || '',
      number: (aviary && aviary.number) || '',
      capacity: (aviary && aviary.capacity) || '',
    },
    validationSchema: Yup.object({
      number: Yup.string().required("Por favor digite a Número"),
      capacity: Yup.string().required("Por favor digite o Capacidade"),

    }),
    onSubmit: (values) => {
    
      if (isEditAviary) {
        const updateOrder = {
          aviary_id: aviary ? aviary.id : 0,
          number: values.number,
          capacity: values.capacity,
        };



      } else {

        const newOrder = {
          id:mortalityList.length +1,
          number: values["number"],
          capacity: values["capacity"],
        };

        addAviaryToTable(newOrder);
        validation.resetForm();

        // save new order
      }

    },
  });




  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        disableFilters: true,

      },
      {
        Header: 'Data',
        accessor: 'date',
        disableFilters: true,
      },
      {
        Header: 'Idade',
        accessor: 'age',
        disableFilters: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
       
            <Badge
            className={`font-size-12 badge-soft-${cellProps.row.original.status === 'ENVIADO' ? 'success' : 'info'}` }
          >
            {cellProps.row.original.status}
          </Badge>
          )
        }
      },
      {
        Header: 'Data de Apontamento',
        accessor: 'registerAt',
        disableFilters: true,
      },
      {
        Header: 'Mortos',
        accessor: 'amountDeath',
        disableFilters: true,
      },
      {
        Header: 'Eliminados',
        accessor: 'amountExclude',
        disableFilters: true,
      },
      {
        Header: 'Ações',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown direction="start">
              <DropdownToggle
                href="#"
                className="card-drop"
                tag="i"
              >
                <i className="mdi mdi-dots-horizontal font-size-18" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">

                <DropdownItem
                  href={"#"}
                  onClick={() => {
                    resetMortality(cellProps.row.original.id);
                  }}
                >
                  <i className="mdi mdi-autorenew font-size-16 text-danger me-1" />{" "}
                  Resetar Apontamento
                </DropdownItem>

              </DropdownMenu>

            </UncontrolledDropdown>
          );
        }
      },
    ],
    []
  );



  const columnsBatch = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        disableFilters: true,

      },
      {
        Header: 'Data',
        accessor: 'date',
        disableFilters: true,
      },
      {
        Header: 'Idade',
        accessor: 'age',
        disableFilters: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Badge
            className={`font-size-12 badge-soft-${cellProps.row.original.status === 'ENVIADO' ? 'success' : 'info'}` }
          >
            {cellProps.row.original.status}
          </Badge>);
        }
    
      },
      {
        Header: 'Data de Apontamento',
        accessor: 'registerAt',
        disableFilters: true,
      },
      {
        Header: 'Peso Total',
        accessor: 'weight',
        disableFilters: true,
      },
      {
        Header: 'Ações',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown direction="start">
              <DropdownToggle
                href="#"
                className="card-drop"
                tag="i"
              >
                <i className="mdi mdi-dots-horizontal font-size-18" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                  onClick={() => {
                    const aviaryData = cellProps.row.original;
                    handleOrderClick(aviaryData);
                  }}
                >
                  <i className="mdi mdi-eye-outline font-size-16 text-danger me-1" />{" "}
                  Ver Detalhes
                </DropdownItem>

                <DropdownItem
                  href={"#"}
                  onClick={() => {
                    resetWeight(cellProps.row.original.id);
                  }}
                >
                  <i className="mdi mdi-autorenew font-size-16 text-danger me-1" />{" "}
                  Resetar Apontamento
                </DropdownItem>

              </DropdownMenu>

            </UncontrolledDropdown>
          );
        }
      },
    ],
    []
  );

  const handleOrderClick = arg => {

    setIsEditAviary(true);

    setAviary({ id: arg.id, number: arg.number, capacity: arg.capacity });

   
    toggleModalAddAviary();



  };

  const handleOrderClicks = () => {

    setIsEditAviary(false);
    toggleModalAddAviary();


  };


  function toggleModalAddAviary() {
    if (modalAddAviary) {
      setModalAddAviary(false);
      setAviary(null);
    } else {
      setModalAddAviary(true)
    }

  }



  const addAviaryToTable = (value) => {
    const modifiedRows = [...mortalityList];

    modifiedRows.push(value);
    setMortalityList(modifiedRows);

    console.log(modifiedRows);
    toggleModalAddAviary();
  }




  function toggleModalAddBatch() {
    if (modalAddBatch) {
      setModalAddBatch(false);
      setBatch(null);
    } else {
      setModalAddBatch(true)
    }

  }



  const addABatchToTable = (value) => {
    const modifiedRows = [...weightList];

    modifiedRows.push(value);
    setWeightList(modifiedRows);

    console.log(modifiedRows);
    toggleModalAddBatch();
  }

  //Setting Menu
  const toggleSettings = () => {
    setSettingsMenu(!settingsMenu)
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  async function resetMortality(id){

    MySwal.fire({
      title: 'Tem certeza que deseja resetar o apontamento de mortalidade?',
      text: 'Essa ação será irreversivel!',
      showCancelButton: true,
      confirmButtonText: 'Resetar',
      cancelButtonText: 'Fechar'
    }).then((result) => {
      if (result.isConfirmed) {
        post(url.CANCEL_MORTALITY+id)
        .finally(()=>{
          MySwal.fire('Tudo certo!', 'Mortalidade resetada com sucesso !', 'success').finally( ()=>{
            getBatchInfo(props.match.params.id);
          });
  
        }).catch(()=>{
          MySwal.fire('Oops...!', 'Erro ao resetar mortalidade', 'error');
        });
      } 
  });

  }


  async function resetWeight(id){

    MySwal.fire({
      title: 'Tem certeza que deseja resetar o apontamento de pesagem?',
      text: 'Essa ação será irreversivel!',
      showCancelButton: true,
      confirmButtonText: 'Resetar',
      cancelButtonText: 'Fechar'
    }).then((result) => {
      if (result.isConfirmed) {
        post(url.CANCEL_WEIGHT+id)
        .finally(()=>{
          MySwal.fire('Tudo certo!', 'Pesagem resetada com sucesso !', 'success').finally( ()=>{
            getBatchInfo(props.match.params.id);
          });
  
        }).catch(()=>{
          MySwal.fire('Oops...!', 'Erro ao resetar pesagem', 'error');
        });
      } 
  });

  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Produção de Frango" breadcrumbItem="Detalhe do Lote" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>


                    <Col lg="4">
                      <div className="d-flex">
                        <div className="flex-grow-1 align-self-center">
                          <div className="text-muted">

                            <h5 className="mb-1">Lote {batchInfo.externalCode} </h5>
                            <p className="mb-0">{batchInfo.farmName} - {batchInfo.farmCode} | Aviário {batchInfo.aviaryCode} | Idade {batchInfo.age} dias</p>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col lg="8" className="align-self-center">
                      <div className="text-lg-center mt-4 mt-lg-0">
                        <Row>
                          <Col xs="4">
                            <div>
                              <p className="text-muted text-truncate mb-2">
                                Mortalidade
                              </p>
                              <h5 className="mb-0">{batchInfo.mortalityQtd} / <Badge className="me-2 bg-success">{batchInfo.mortalityPercentage}%</Badge></h5>
                            </div>
                          </Col>
                          <Col xs="4">
                            <div>
                              <p className="text-muted text-truncate mb-2">
                                Pesagem
                              </p>
                              <h5 className="mb-0">{batchInfo.weightQtd} KG / <Badge className="me-2 bg-danger">{batchInfo.weightPercentage}%</Badge></h5>
                            </div>
                          </Col>
                          <Col xs="4">
                            <div>
                              <p className="text-muted text-truncate mb-2">
                                Aves
                              </p>
                              <h5 className="mb-0">{batchInfo.headAmountActual} / {batchInfo.headAmountInventory}</h5>
                            </div>
                          </Col>

                        </Row>
                      </div>
                    </Col>

                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom nav-justified justify-content-center">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">Mortalidade</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustom("2");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">Pesagem</span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent
                    activeTab={customActiveTab}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">

                          <TableContainer
                            columns={columns}
                            data={mortalityList}
                            isGlobalFilter={true}
                            customPageSize={10}
                            isAddOptions={false}
                            customPageSizeOptions={2}
                            handleOrderClicks={handleOrderClicks}
                          //className="custom-header-css"
                          />

                          <Modal
                            size="lg"
                            isOpen={modalAddAviary}
                            toggle={() => {
                              toggleModalAddAviary();
                            }}
                          >
                            <ModalHeader toggle={toggleModalAddAviary} tag="h4">
                              {"Detalhes do apontamento"}
                            </ModalHeader>

                            <ModalBody>
                            <Table className="table table-bordered mb-0">
                            <thead>
                              <tr>
                                <th>Pesada</th>
                                <th>Aves</th>
                                <th>Peso</th>
                              </tr>
                            </thead>
                            <tbody>
                            {(weightValues || []).map((values, key) => (
                              <tr key={key}>
                                <th>{values.code}</th>
                                <td>{values.generedlegend}</td>
                                <td>{values.pictures.length}</td>
                              </tr>
                            ))}
                            </tbody>


                          </Table>

                          <button
                            type="button"
                            onClick={() => {
                              toggleModalAddAviary();
                            }}
                            className="btn btn-secondary mt-3 "
                            data-dismiss="modal"
                          >
                            Sair
                          </button>
                            </ModalBody>
                          </Modal>
                        </Col>
                      </Row>
                    </TabPane>
                 
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <TableContainer
                            columns={columnsBatch}
                            data={weightList}
                            isGlobalFilter={true}
                            customPageSize={10}
                            isAddOptions={false}
                            customPageSizeOptions={2}
                      
                          //className="custom-header-css"
                          />



                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>

          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
}

export default BatchDetail;
