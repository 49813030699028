import React, { useMemo, useEffect, useState } from "react"
import {
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Col,
  Row,
  Label,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import TableContainer from '../../../components/Common/TableContainer';
import Breadcrumbs from "../../../components/Common/Breadcrumb";


import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { useFormik } from "formik"
import * as Yup from "yup";

import { get,post } from "../../../helpers/api_helper";
import * as url from "../../../helpers/url_helper";
const Batch = () => {
  //meta title
  //meta title
  document.title = "NattoLabs - Painel Adminitrativo";

  const MySwal = withReactContent(Swal);

  const [modalAddBatch, setModalAddBatch] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [batch, setBatch] = useState(null);
  const [batchList, setBatchList] = useState([]);


  useEffect(()=> getBatchs(),[])


  function getBatchs(){
    
   get(url.GET_BATCHS_LIST).then((result)=>{
    setBatchList(result.entityModelList);
    });
  }


  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (batch && batch.id) || '',
      code: (batch && batch.code) || '',
      name: (batch && batch.name) || '',
    },
    validationSchema: Yup.object({
      code: Yup.string().required("Por favor digite a Código"),
      name: Yup.string().required("Por favor digite o Nome"),

    }),
    onSubmit: (values) => {

      if (isEdit) {
        const updateOrder = {
          batch_id: batch ? batch.id : 0,
          name: values.name,
          code: values.code,
        };



      } else {

        const newOrder = {
          id: batchList.length + 1,
          name: values["name"],
          code: values["code"],
        };

        addBatchToTable(newOrder);
        validation.resetForm();

        // save new order
      }

    },
  });

  const handleOrderClick = arg => {

    setIsEdit(true);

    setBatch({ id: arg.id, name: arg.name, code: arg.code });

    toggleModalAddBatch();



  };

  const handleOrderClicks = () => {

    setIsEdit(false);
    toggleModalAddBatch();


  };


  function toggleModalAddBatch() {
    if (modalAddBatch) {
      setModalAddBatch(false);
      setBatch(null);
    } else {
      setModalAddBatch(true)
    }

  }



  const addBatchToTable = (value) => {
    const modifiedRows = [...batchList];

    modifiedRows.push(value);
    setBatchList(modifiedRows);

    toggleModalAddBatch();
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        disableFilters: true,

      },
      {
        Header: 'Numero',
        accessor: 'externalCode',
        disableFilters: true,
      },
      {
        Header: 'Qtd Alojadas',
        accessor: 'headAmountInventory',
        disableFilters: true,
      },
      {
        Header: 'Data de Alojamento',
        accessor: 'placementDate',
        disableFilters: true,
      },
      {
        Header: 'Data Final',
        accessor: 'dateSlaughter',
        disableFilters: true,
      },
      {
        Header: 'Granja',
        accessor: 'farmName',
        disableFilters: true,
      },
      {
        Header: 'Código Granja',
        accessor: 'farmCode',
        disableFilters: true,
      },
      {
        Header: 'Aviáio',
        accessor: 'houseCode',
        disableFilters: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Badge
            className={"font-size-12 bg-success"}
          >
           {cellProps.row.original.status}
          </Badge>);
        }
      },
      {
        Header: 'Ações',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown direction="start">
              <DropdownToggle
                href="#"
                className="card-drop"
                tag="i"
              >
                <i className="mdi mdi-dots-horizontal font-size-18" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">

                <DropdownItem
                  href={"/cpbatchdetail/"+cellProps.row.original.id}
                >
                  <i className="mdi mdi-eye font-size-16 text-danger me-1" />{" "}
                  Ver Detalhes
                </DropdownItem>

                {/* <DropdownItem
                  onClick={() => {
                    const batchData = cellProps.row.original;
                    handleOrderClick(batchData);
                  }}
                >
                  <i className="mdi mdi-head-sync-outline font-size-16 text-danger me-1" />{" "}
                  Replanejar
                </DropdownItem> */}



                <DropdownItem
                  href={"#"}
                  onClick={() => {
                    cancelBatch(cellProps.row.original.id);
                  }}
                >
                  <i className="mdi mdi-file-excel-box font-size-16 text-danger me-1" />{" "}
                  Cancelar
                </DropdownItem>

                <DropdownItem
                  href={"#"}
                  onClick={() => {
                    disableBatch(cellProps.row.original.id);
                  }}
                >
                  <i className="mdi mdi-cancel font-size-16 text-danger me-1" />{" "}
                  Desabilitar
                </DropdownItem>

              </DropdownMenu>

            </UncontrolledDropdown>
          );
        }
      },
    ],
    []
  );


  async function cancelBatch(id){

    MySwal.fire({
      title: 'Tem certeza que deseja cancelar o lote?',
      text: 'Essa ação será irreversivel!',
      showCancelButton: true,
      confirmButtonText: 'Cancelar',
      cancelButtonText: 'Fechar'
    }).then((result) => {
      if (result.isConfirmed) {
        post(url.CANCEL_BATCH+id)
        .finally(()=>{
          MySwal.fire('Tudo certo!', 'Lote cancelado com sucesso !', 'success').finally( ()=>{
            getBatchs();
          });
  
        }).catch(()=>{
          MySwal.fire('Oops...!', 'Erro ao cancelar lote', 'error');
        });
      } 
  });

  }

  async function disableBatch(id){
    MySwal.fire({
      title: 'Tem certeza que deseja desabilitar o lote?',
      text: 'Essa ação será irreversivel!',
      showCancelButton: true,
      confirmButtonText: 'Desabilitar',
      cancelButtonText: 'Fechar'
    }).then((result) => {
      if (result.isConfirmed) {
        post(url.DISABLE_BATCH+id)
        .finally(()=>{
          MySwal.fire('Tudo certo!', 'Lote desabilitado com sucesso !', 'success').finally( ()=>{
            getBatchs();
          });
  
        }).catch(()=>{
          MySwal.fire('Oops...!', 'Erro ao desabilitado lote', 'error');
        });
      } 
  });
  }



  

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Produção de Frango" breadcrumbItem="Listagem de Lotes" />
          <TableContainer
            columns={columns}
            data={batchList}
            isGlobalFilter={true}
            customPageSize={10}
            isAddOptions={false}
            customPageSizeOptions={2}
            //handleOrderClicks={handleOrderClicks}
          //className="custom-header-css"
          />

          <Modal
            size="lg"
            isOpen={modalAddBatch}
            toggle={() => {
              toggleModalAddBatch();
            }}
          >
            <ModalHeader toggle={toggleModalAddBatch} tag="h4">
              {!!isEdit ? "Editar Lote" : "Adicionar Lote"}
            </ModalHeader>

            <ModalBody>
              <Form
                onSubmit={validation.handleSubmit}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Quantidade de Aves</Label>

                      <Input
                        type="number"
                        defaultValue="0"
                        id="example-date-input"
                      />

                    </div>


                    <div className="mb-3">
                      <Label className="form-label">Código</Label>
                      <Input
                        name="code"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.code || ""}
                        invalid={
                          validation.touched.code && validation.errors.code ? true : false
                        }
                      />
                      {validation.touched.code && validation.errors.code ? (
                        <FormFeedback type="invalid">{validation.errors.code}</FormFeedback>
                      ) : null}
                    </div>


                    <div className="mb-3">
                      <Label className="form-label">Data de Alojamento</Label>

                      <Input
                        type="date"
                        defaultValue="2019-08-19"
                        id="example-date-input"
                      />

                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Duração</Label>

                      <Input
                        type="number"
                        defaultValue="1"
                        id="example-date-input"
                      />

                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Granja</Label>
                      <Input
                        name="farm_id"
                        type="select"
                        className="form-select"
                        onChange={e => {
                          getSystemByArea(e.target.value)
                        }}
                      >
                        <option value="">Selecione...</option>
                        {/* {(farm || []).map((row, key) => (
                                <option key={key} value={row.id}>{row.name}</option>
                                ))} */}
                      </Input>

                    </div>


                    <div className="mb-3">
                      <Label className="form-label">Aviário</Label>
                      <Input
                        name="aviary_id"
                        type="select"
                        className="form-select"
                        onChange={e => {
                          getSystemByArea(e.target.value)
                        }}
                      >
                        <option value="">Selecione...</option>
                        {/* {(aviary || []).map((row, key) => (
                                <option key={key} value={row.id}>{row.name}</option>
                                ))} */}
                      </Input>

                    </div>
                  </Col>

                </Row>

                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Salvar
                      </button>
                    </div>
                  </Col>
                </Row>

              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Batch;
