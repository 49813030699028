import React, { useMemo, useEffect, useState } from "react"
import {
  UncontrolledTooltip,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Col,
  Row,
  Label,
  Card,
  CardBody,
  CardTitle,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledDropdown
} from "reactstrap"
import TableContainer from '../../../../components/Common/TableContainer';
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import Select from "react-select";

import classnames from "classnames"

import InputMask from "react-input-mask";

import { Link } from "react-router-dom";

import { useFormik } from "formik"

import * as Yup from "yup";

import img5 from "../../../../assets/images/companies/img-5.png";

const FarmDetail = () => {
  //meta title
  document.title = "NattoLabs - Painel Adminitrativo";

  const [settingsMenu, setSettingsMenu] = useState(false);
  const [customActiveTab, setcustomActiveTab] = useState("1");

  const [aviaryList, setAviaryList] = useState([{ id: 1, number: "01", capacity: 25000 }]);
  const [modalAddAviary, setModalAddAviary] = useState(false);
  const [isEditAviary, setIsEditAviary] = useState(false);
  const [aviary, setAviary] = useState(null);


  const [boxList, setBoxList] = useState([{ id: 1,aviary:"01", number: "01", capacity: 25000 }]);
  const [modalAddBox, setModalAddBox] = useState(false);
  const [isEditBox, setIsEditBox] = useState(false);
  const [box, setBox] = useState(null);

  const [batchList, setBatchList] = useState([{id:1,external_code:"123-32-1",aviary:"01",status:"ATIVO",date_placement:"09/09/2023",age:32,head_amount_inventory:25500,head_amount_actual:25000}]);
  const [modalAddBatch, setModalAddBatch] = useState(false);
  const [isEditBatch, setIsEditBatch] = useState(false);
  const [batch, setBatch] = useState(null);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (aviary && aviary.id) || '',
      number: (aviary && aviary.number) || '',
      capacity: (aviary && aviary.capacity) || '',
    },
    validationSchema: Yup.object({
      number: Yup.string().required("Por favor digite a Número"),
      capacity: Yup.string().required("Por favor digite o Capacidade"),

    }),
    onSubmit: (values) => {
    
      if (isEditAviary) {
        const updateOrder = {
          aviary_id: aviary ? aviary.id : 0,
          number: values.number,
          capacity: values.capacity,
        };



      } else {

        const newOrder = {
          id:aviaryList.length +1,
          number: values["number"],
          capacity: values["capacity"],
        };

        addAviaryToTable(newOrder);
        validation.resetForm();

        // save new order
      }

    },
  });


  const validationBox = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (box && box.id) || '',
      aviary: (box && box.aviary) || '',
      number: (box && box.number) || '',
      capacity: (box && box.capacity) || '',
    },
    validationSchema: Yup.object({
      number: Yup.string().required("Por favor digite a Número"),
      aviary: Yup.string().required("Por favor digite a Número"),
      capacity: Yup.string().required("Por favor digite o Capacidade"),

    }),
    onSubmit: (values) => {
    
      if (isEditBox) {
        const updateOrder = {
          box_id: aviary ? box.id : 0,
          aviary:  values.aviary,
          number: values.number,
          capacity: values.capacity,
        };



      } else {

        const newOrder = {
          id:boxList.length +1,
          aviary: values["aviary"],
          number: values["number"],
          capacity: values["capacity"],
        };

        addABoxToTable(newOrder);
        validationBox.resetForm();

        // save new order
      }

    },
  });

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        disableFilters: true,

      },
      {
        Header: 'Número',
        accessor: 'number',
        disableFilters: true,
      },
      {
        Header: 'Capacidade',
        accessor: 'capacity',
        disableFilters: true,
      },
      {
        Header: 'Ações',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown direction="start">
              <DropdownToggle
                href="#"
                className="card-drop"
                tag="i"
              >
                <i className="mdi mdi-dots-horizontal font-size-18" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={() => {
                    const aviaryData = cellProps.row.original;
                    handleOrderClick(aviaryData);
                  }}
                >
                  <i className="mdi mdi-pencil font-size-16 text-danger me-1" />{" "}
                  Editar
                </DropdownItem>


                <DropdownItem
                  href={"#"}
                >
                  <i className="mdi mdi-cancel font-size-16 text-danger me-1" />{" "}
                  Desabilitar
                </DropdownItem>

              </DropdownMenu>

            </UncontrolledDropdown>
          );
        }
      },
    ],
    []
  );

  const columnsBox = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        disableFilters: true,

      },
      {
        Header: 'Aviário',
        accessor: 'aviary',
        disableFilters: true,
      },
      {
        Header: 'Número',
        accessor: 'number',
        disableFilters: true,
      },
      {
        Header: 'Capacidade',
        accessor: 'capacity',
        disableFilters: true,
      },
      {
        Header: 'Ações',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown direction="start">
              <DropdownToggle
                href="#"
                className="card-drop"
                tag="i"
              >
                <i className="mdi mdi-dots-horizontal font-size-18" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={() => {
                    const boxData = cellProps.row.original;
                    handleBoxClick(boxData);
                  }}
                >
                  <i className="mdi mdi-pencil font-size-16 text-danger me-1" />{" "}
                  Editar
                </DropdownItem>


                <DropdownItem
                  href={"#"}
                >
                  <i className="mdi mdi-cancel font-size-16 text-danger me-1" />{" "}
                  Desabilitar
                </DropdownItem>

              </DropdownMenu>

            </UncontrolledDropdown>
          );
        }
      },
    ],
    []
  );

  const columnsBatch = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        disableFilters: true,

      },
      {
        Header: 'Código',
        accessor: 'external_code',
        disableFilters: true,
      },
      {
        Header: 'Aviário',
        accessor: 'aviary',
        disableFilters: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableFilters: true,
      },
      {
        Header: 'Data de Alocamento',
        accessor: 'date_placement',
        disableFilters: true,
      },
      {
        Header: 'Idade',
        accessor: 'age',
        disableFilters: true,
      },
      {
        Header: 'Qtd Alocada',
        accessor: 'head_amount_inventory',
        disableFilters: true,
      },
      {
        Header: 'Qtd Atual',
        accessor: 'head_amount_actual',
        disableFilters: true,
      },
      {
        Header: 'Ações',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown direction="start">
              <DropdownToggle
                href="#"
                className="card-drop"
                tag="i"
              >
                <i className="mdi mdi-dots-horizontal font-size-18" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={() => {
                    const aviaryData = cellProps.row.original;
                    handleOrderClick(aviaryData);
                  }}
                >
                  <i className="mdi mdi-pencil font-size-16 text-danger me-1" />{" "}
                  Ver Detalhes
                </DropdownItem>


                <DropdownItem
                  href={"#"}
                >
                  <i className="mdi mdi-cancel font-size-16 text-danger me-1" />{" "}
                  Desabilitar
                </DropdownItem>

              </DropdownMenu>

            </UncontrolledDropdown>
          );
        }
      },
    ],
    []
  );

  const handleOrderClick = arg => {

    setIsEditAviary(true);

    setAviary({ id: arg.id, number: arg.number, capacity: arg.capacity });

   
    toggleModalAddAviary();



  };

  const handleOrderClicks = () => {

    setIsEditAviary(false);
    toggleModalAddAviary();


  };


  function toggleModalAddAviary() {
    if (modalAddAviary) {
      setModalAddAviary(false);
      setAviary(null);
    } else {
      setModalAddAviary(true)
    }

  }



  const addAviaryToTable = (value) => {
    const modifiedRows = [...aviaryList];

    modifiedRows.push(value);
    setAviaryList(modifiedRows);

    console.log(modifiedRows);
    toggleModalAddAviary();
  }




  const handleBoxClick = arg => {

    setIsEditBox(true);

    setBox({ id: arg.id, number: arg.number, capacity: arg.capacity });

   
    toggleModalAddBox();



  };

  const handleBoxClicks = () => {

    setIsEditBox(false);
    toggleModalAddBox();


  };


  function toggleModalAddBox() {
    if (modalAddBox) {
      setModalAddBox(false);
      setBox(null);
    } else {
      setModalAddBox(true)
    }

  }



  const addABoxToTable = (value) => {
    const modifiedRows = [...boxList];

    modifiedRows.push(value);
    setBoxList(modifiedRows);

    console.log(modifiedRows);
    toggleModalAddBox();
  }



  const handleBatchClick = arg => {

    setIsEditBatch(true);

    setBatch({ id: arg.id, number: arg.number, capacity: arg.capacity });

   
    toggleModalAddBatch();



  };

  const handleBatchClicks = () => {

    setIsEditBatch(false);
    toggleModalAddBatch();


  };


  function toggleModalAddBatch() {
    if (modalAddBatch) {
      setModalAddBatch(false);
      setBatch(null);
    } else {
      setModalAddBatch(true)
    }

  }



  const addABatchToTable = (value) => {
    const modifiedRows = [...batchList];

    modifiedRows.push(value);
    setBatchList(modifiedRows);

    console.log(modifiedRows);
    toggleModalAddBatch();
  }

  //Setting Menu
  const toggleSettings = () => {
    setSettingsMenu(!settingsMenu)
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Produção de Frango" breadcrumbItem="Detalhe de Granja" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>

                    <Col lg="1" className="align-self-end">
                      <img src={img5} className="rounded avatar-sm" />
                    </Col>


                    <Col lg="4">
                      <div className="d-flex">
                        <div className="flex-grow-1 align-self-center">
                          <div className="text-muted">

                            <h5 className="mb-1">Granja de Agrestina</h5>
                            <p className="mb-0">108</p>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col lg="4" className="align-self-center">
                      <div className="text-lg-center mt-4 mt-lg-0">
                        <Row>
                          <Col xs="4">
                            <div>
                              <p className="text-muted text-truncate mb-2">
                                Aviários
                              </p>
                              <h5 className="mb-0">8</h5>
                            </div>
                          </Col>
                          <Col xs="4">
                            <div>
                              <p className="text-muted text-truncate mb-2">
                                Lotes Ativos
                              </p>
                              <h5 className="mb-0">8</h5>
                            </div>
                          </Col>
                          <Col xs="4">
                            <div>
                              <p className="text-muted text-truncate mb-2">
                                Aves Alojadas
                              </p>
                              <h5 className="mb-0">35.000</h5>
                            </div>
                          </Col>

                        </Row>
                      </div>
                    </Col>

                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom nav-justified justify-content-center">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">Aviários</span>
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustom("2");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">Box</span>
                      </NavLink>
                    </NavItem> */}
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "3",
                        })}
                        onClick={() => {
                          toggleCustom("3");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">Lotes</span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent
                    activeTab={customActiveTab}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">

                          <TableContainer
                            columns={columns}
                            data={aviaryList}
                            isGlobalFilter={true}
                            customPageSize={10}
                            isAddOptions={true}
                            customPageSizeOptions={2}
                            handleOrderClicks={handleOrderClicks}
                          //className="custom-header-css"
                          />

                          <Modal
                            size="lg"
                            isOpen={modalAddAviary}
                            toggle={() => {
                              toggleModalAddAviary();
                            }}
                          >
                            <ModalHeader toggle={toggleModalAddAviary} tag="h4">
                              {!!isEditAviary ? "Editar Aviário" : "Adicionar Aviário"}
                            </ModalHeader>

                            <ModalBody>
                              <Form
                                onSubmit={validation.handleSubmit}
                              >
                                <Row>
                                  <Col className="col-12">
                                    <div className="mb-3">
                                      <Label className="form-label">Número</Label>
                                      <Input
                                        name="number"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.number || ""}
                                        invalid={
                                          validation.touched.number && validation.errors.number ? true : false
                                        }
                                      />
                                      {validation.touched.number && validation.errors.number ? (
                                        <FormFeedback type="invalid">{validation.errors.number}</FormFeedback>
                                      ) : null}
                                    </div>


                                    <div className="mb-3">
                                      <Label className="form-label">Capacidade</Label>
                                      <Input
                                        name="capacity"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.capacity || ""}
                                        invalid={
                                          validation.touched.capacity && validation.errors.capacity ? true : false
                                        }
                                      />
                                      {validation.touched.capacity && validation.errors.capacity ? (
                                        <FormFeedback type="invalid">{validation.errors.capacity}</FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>

                                </Row>

                                <Row>
                                  <Col>
                                    <div className="text-end">
                                      <button
                                        type="submit"
                                        className="btn btn-success save-user"
                                      >
                                        Salvar
                                      </button>
                                    </div>
                                  </Col>
                                </Row>

                              </Form>
                            </ModalBody>
                          </Modal>
                        </Col>
                      </Row>
                    </TabPane>
                    {/* <TabPane tabId="2">
                      <Row>
                        <Col sm="12">

                        <TableContainer
                            columns={columnsBox}
                            data={boxList}
                            isGlobalFilter={true}
                            customPageSize={10}
                            isAddOptions={true}
                            customPageSizeOptions={2}
                            handleOrderClicks={handleBoxClicks}
                          //className="custom-header-css"
                          />

                          <Modal
                            size="lg"
                            isOpen={modalAddBox}
                            toggle={() => {
                              toggleModalAddBox();
                            }}
                          >
                            <ModalHeader toggle={toggleModalAddBox} tag="h4">
                              {!!isEditBox ? "Editar Box" : "Adicionar Box"}
                            </ModalHeader>

                            <ModalBody>
                              <Form
                                onSubmit={validation.handleSubmit}
                              >
                                <Row>
                                  <Col className="col-12">
                                    <div className="mb-3">
                                      <Label className="form-label">Número</Label>
                                      <Input
                                        name="number"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.number || ""}
                                        invalid={
                                          validation.touched.number && validation.errors.number ? true : false
                                        }
                                      />
                                      {validation.touched.number && validation.errors.number ? (
                                        <FormFeedback type="invalid">{validation.errors.number}</FormFeedback>
                                      ) : null}
                                    </div>


                                    <div className="mb-3">
                                      <Label className="form-label">Capacidade</Label>
                                      <Input
                                        name="capacity"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.capacity || ""}
                                        invalid={
                                          validation.touched.capacity && validation.errors.capacity ? true : false
                                        }
                                      />
                                      {validation.touched.capacity && validation.errors.capacity ? (
                                        <FormFeedback type="invalid">{validation.errors.capacity}</FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>

                                </Row>

                                <Row>
                                  <Col>
                                    <div className="text-end">
                                      <button
                                        type="submit"
                                        className="btn btn-success save-user"
                                      >
                                        Salvar
                                      </button>
                                    </div>
                                  </Col>
                                </Row>

                              </Form>
                            </ModalBody>
                          </Modal>
                        </Col>
                      </Row>
                    </TabPane> */}

                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12">
                          <TableContainer
                            columns={columnsBatch}
                            data={batchList}
                            isGlobalFilter={true}
                            customPageSize={10}
                            isAddOptions={false}
                            customPageSizeOptions={2}
                      
                          //className="custom-header-css"
                          />



                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>

          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
}

export default FarmDetail;
