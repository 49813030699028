import React, { useMemo, useEffect, useState } from "react"
import {
  UncontrolledTooltip,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Col,
  Row,
  Label,
  Card,
  CardBody,
  CardTitle,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import TableContainer from '../../../components/Common/TableContainer';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Select from "react-select";

import InputMask from "react-input-mask";

import { Link } from "react-router-dom";

import { useFormik } from "formik"
import * as Yup from "yup";


const Farm = () => {
  //meta title
  document.title = "NattoLabs - Painel Adminitrativo";

  const [modalAddFarm, setModalAddFarm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [farm, setFarm] = useState(null);
  const [farmList, setFarmList] = useState([{ id: 1, code: "108", name: "Granja de Agrestina" }]);


  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (farm && farm.id) || '',
      code: (farm && farm.code) || '',
      name: (farm && farm.name) || '',
    },
    validationSchema: Yup.object({
      code: Yup.string().required("Por favor digite a Código"),
      name: Yup.string().required("Por favor digite o Nome"),

    }),
    onSubmit: (values) => {
    
      if (isEdit) {
        const updateOrder = {
          farm_id: farm ? farm.id : 0,
          name: values.name,
          code: values.code,
        };



      } else {

        const newOrder = {
          id:farmList.length +1,
          name: values["name"],
          code: values["code"],
        };

        addFarmToTable(newOrder);
        validation.resetForm();

        // save new order
      }

    },
  });

  const handleOrderClick = arg => {
   
    setIsEdit(true);

    setFarm({id:arg.id,name:arg.name,code:arg.code});

    toggleModalAddFarm();
    


  };

  const handleOrderClicks = () => {
    
    setIsEdit(false);
    toggleModalAddFarm();


  };


  function toggleModalAddFarm() {
    if(modalAddFarm)
    {
      setModalAddFarm(false);
      setFarm(null);
    }else{
      setModalAddFarm(true)
    }

  }



  const addFarmToTable = (value) => {
    const modifiedRows = [...farmList];

    modifiedRows.push(value);
    setFarmList(modifiedRows);
   
    toggleModalAddFarm();
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        disableFilters: true,

      },
      {
        Header: 'Código',
        accessor: 'code',
        disableFilters: true,
      },
      {
        Header: 'Nome',
        accessor: 'name',
        disableFilters: true,
      },
      {
        Header: 'Ações',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown direction="start">
              <DropdownToggle
                href="#"
                className="card-drop"
                tag="i"
              >
                <i className="mdi mdi-dots-horizontal font-size-18" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">




                <DropdownItem
                  href={"/cpfarmdetail/"}
                >
                  <i className="mdi mdi-eye font-size-16 text-danger me-1" />{" "}
                  Ver Detalhes
                </DropdownItem>

                <DropdownItem
                onClick={() => {
                  const farmData = cellProps.row.original;
                  handleOrderClick(farmData);
                }}
                >
                  <i className="mdi mdi-pencil font-size-16 text-danger me-1" />{" "}
                  Editar
                </DropdownItem>


                <DropdownItem
                  href={"#"}
                >
                  <i className="mdi mdi-cancel font-size-16 text-danger me-1" />{" "}
                  Desabilitar
                </DropdownItem>






              </DropdownMenu>

            </UncontrolledDropdown>
          );
        }
      },
    ],
    []
  );


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Breadcrumbs title="Produção de Frango" breadcrumbItem="Listagem de Granja" />
          <TableContainer
            columns={columns}
            data={farmList}
            isGlobalFilter={true}
            customPageSize={10}
            isAddOptions={true}
            customPageSizeOptions={2}
            handleOrderClicks={handleOrderClicks}
          //className="custom-header-css"
          />

          <Modal
            size="lg"
            isOpen={modalAddFarm}
            toggle={() => {
              toggleModalAddFarm();
            }}
          >
            <ModalHeader toggle={toggleModalAddFarm} tag="h4">
              {!!isEdit ? "Editar Granja" : "Adicionar Graja"}
            </ModalHeader>

            <ModalBody>
              <Form
                onSubmit={validation.handleSubmit}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Nome</Label>
                      <Input
                        name="name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                          validation.touched.name && validation.errors.name ? true : false
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                      ) : null}
                    </div>


                    <div className="mb-3">
                      <Label className="form-label">Código</Label>
                      <Input
                        name="code"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.code || ""}
                        invalid={
                          validation.touched.code && validation.errors.code ? true : false
                        }
                      />
                      {validation.touched.code && validation.errors.code ? (
                        <FormFeedback type="invalid">{validation.errors.code}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                </Row>

                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Salvar
                      </button>
                    </div>
                  </Col>
                </Row>

              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Farm;
